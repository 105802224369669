import React, { useState } from 'react'

import { ArrowButton } from 'components/inputs'


type CollapsableBlockProps = {
  className?: string
  title: string
}

const CollapsableBlock: React.FC<CollapsableBlockProps> = (props) => {
  const { className, title, children } = props

  const [ isOpen, setIsOpen ] = useState<boolean>(true)

  return (
    <div className={className}>
      <ArrowButton
        title={title}
        arrow={isOpen ? 'up' : 'down'}
        position="right"
        color="titanic"
        onClick={() => setIsOpen(!isOpen)}
      />
      {
        isOpen && children
      }
    </div>
  )
}

export default CollapsableBlock